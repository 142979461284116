import ScrollMagic from 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationSingleProject {
    constructor() {
        this.ready();
    }
    
    ready() {
        
        const offsetAnimations = -300;
        const controller = new ScrollMagic.Controller();
        
        const heroProjeto = document.querySelector('.single-projetos .heroPage');
        if(heroProjeto) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+heroProjeto.className
            })
            .setClassToggle("."+heroProjeto.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
        const modImages = document.querySelector('.single-projetos .modImages');
        if(modImages) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+modImages.className
            })
            .setClassToggle("."+modImages.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
        const galeria = document.querySelector('.single-projetos .galeria');
        if(galeria) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+galeria.className
            })
            .setClassToggle("."+galeria.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
    }
}