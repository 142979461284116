"use strict";

//Modules

//Components
import Vitrine from "./components/vitrine";
import TemplateSobre from "./components/template-sobre";
import PhotoZoom from "./components/photo-zoom";
import TemplateProjetos from "./components/template-projetos";
import SliderProjeto from "./components/slider-projeto";
import Video from "./components/video";
import TemplateAreas from "./components/template-areas";
import Header from "./components/header";
import Contato from "./components/contato";
import Sobre from "./components/sobre";
import AnimationHome from "./animations/home";
import AnimationHeader from "./animations/header";
import AnimationTemplateProjetos from "./animations/template-projetos";
import AnimationArchiveProject from "./animations/archive-projetos";
import AnimationSingleProject from "./animations/single-projetos";
import AnimationSingleContact from "./animations/single-contato";
import AnimationSingleSobre from "./animations/single-sobre";
import Footer from "./components/footer";

export default class App {
  constructor() {
    new Header();
    new Footer();

    //Templates
    const sliderVitrine = document.querySelectorAll(".sliderVitrine");
    if (sliderVitrine.length > 0) {
      new Vitrine();
    }

    const templateSobre = document.querySelectorAll(".template-sobre");
    if (templateSobre.length > 0) {
      new TemplateSobre();
    }

    const zoomImage = document.querySelectorAll(".openModalPhotoZoom");
    if (zoomImage.length > 0) {
      new PhotoZoom();
    }

    const templateProjetos = document.querySelectorAll(".template-projetos");
    if (templateProjetos.length > 0) {
      new TemplateProjetos();
      new AnimationTemplateProjetos();
    }

    const sliderProjeto = document.querySelectorAll(
      ".single-projetos .swiper--projeto"
    );
    if (sliderProjeto.length > 0) {
      new SliderProjeto();
    }

    const embedVideo = document.querySelectorAll(".embed-youtube");
    if (embedVideo.length > 0) {
      new Video();
    }

    const templateAreas = document.querySelectorAll(".template-areas");
    if (templateAreas.length > 0) {
      new TemplateAreas();
    }

    const singleContato = document.querySelectorAll(".single-contato");
    if (singleContato.length > 0) {
      new Contato();
    }

    const singleSobre = document.querySelectorAll(".single-sobre");
    if (singleSobre.length > 0) {
      new Sobre();
    }

    //Animations
    new AnimationHeader();

    const animeHome = document.querySelector(".page-home");
    if (animeHome) {
      new AnimationHome();
    }

    const animeProjetoList = document.querySelector(".archive-projetos");
    if (animeProjetoList) {
      new AnimationArchiveProject();
    }

    const animeSingleProjeto = document.querySelector(
      "section.single-projetos"
    );
    if (animeSingleProjeto) {
      new AnimationSingleProject();
    }

    const animeSingleContact = document.querySelector("section.single-contato");
    if (animeSingleContact) {
      new AnimationSingleContact();
    }

    const animateSingleSobre = document.querySelector(".single-sobre");
    if (animateSingleSobre) {
      new AnimationSingleSobre();
    }

    // var mousePosX = 0;
    // var mousePosY = 0;
    // var mouseDotC = document.getElementById("mouse-dotC");
    // var mouseDotA = document.getElementById("mouse-dot-active");
    // var mouseCircle = document.getElementById("mouse-circle");

    // document.onmousemove = function (e) {
    //   //Update mouse positions
    //   mousePosX = e.pageX;
    //   mousePosY = e.pageY;

    //   //Move dot to mouse position
    //   mouseDotC.style.top = mousePosY + "px";
    //   mouseDotC.style.left = mousePosX + "px";
    // };

    // var delay = 6;
    // var revisedMousePosX = 0;
    // var revisedMousePosY = 0;

    // //Create a Delayed mouse follow
    // function delayMouseFollow() {
    //   requestAnimationFrame(delayMouseFollow);

    //   //Calcculate Delay
    //   revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
    //   revisedMousePosY += (mousePosY - revisedMousePosY) / delay;

    //   mouseCircle.style.top = revisedMousePosY + "px";
    //   mouseCircle.style.left = revisedMousePosX + "px";
    // }
    // delayMouseFollow();

    // var getAllLinks = document.getElementsByTagName("A");
    // for (var i = 0; i < getAllLinks.length; i++) {
    //   getAllLinks[i].addEventListener("mouseover", function (e) {
    //     mouseDotA.classList.add("linkHover");
    //   });
    //   getAllLinks[i].addEventListener("mouseleave", function (e) {
    //     mouseDotA.classList.remove("linkHover");
    //   });
    // }
  }
}

new App();
