import { tns } from "tiny-slider";

export default class TemplateSobre {
    constructor() {
        this.ready();
    }

    ready() {
        const tnsCarousel = document.querySelectorAll(".sliderTemplateSobre");
        const contentText = document.querySelector(".text p");
        const btnSeeMore = document.querySelector(".see-more");
        const currentLang = document.documentElement.lang;

        if (contentText.offsetHeight > 135) {
            contentText.style.maxHeight = "135px";
            btnSeeMore.classList.add("visible");

            let btnLangMore = "See more";
            let btnLangLess = "See less";
            if (document.documentElement.lang == "pt-br") {
                btnLangMore = "Saiba mais";
                btnLangLess = "Saiba menos";
            }

            btnSeeMore.addEventListener("click", () => {
                contentText.classList.toggle("open");
                contentText.classList.contains("open")
                    ? (btnSeeMore.innerHTML = btnLangLess)
                    : (btnSeeMore.innerHTML = btnLangMore);
            });
        }

        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach((slider) => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: false,
                nav: true,
                autoHeight: false,
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                },
            });
        });
    }
}
