export default class Video {
    constructor() {
        this.ready();
    }

    ready() {

        let YouTubeContainers = document.querySelectorAll(".embed-youtube");

        
        // Iterate over every YouTube container you may have
        for (let i = 0; i < YouTubeContainers.length; i++) {
            let container = YouTubeContainers[i];
            let imageSource = container.dataset.videoImage; 

            // Load the Thumbnail Image asynchronously
            let image = new Image();
            image.src = imageSource;
            image.alt = "Ronaldo Rezende - Assista ao vídeo";
            image.addEventListener("load", function() {
                container.appendChild(image);
            });

            // When the user clicks on the container, load the embedded YouTube video
            container.addEventListener("click", function() {
                let iframe = document.createElement( "iframe" );

                iframe.setAttribute("frameborder", "0");
                iframe.setAttribute("allowfullscreen", "");
                iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
                // Important: add the autoplay GET parameter, otherwise the user would need to click over the YouTube video again to play it 
                iframe.setAttribute("src", this.dataset.videoId);

                // Clear Thumbnail and load the YouTube iframe
                this.innerHTML = "";
                this.appendChild( iframe );
            });
        }
    }

    removeIframe($selector) {
        const selectorRemove = document.querySelector($selector)
        selectorRemove('.embed-youtube iframe').remove()
    }

    mountSingleVideo() {

    }

}