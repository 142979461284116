import axios from "axios";

export default class TemplateProjetos {
  constructor() {
    this.btnLoadMore = document.getElementById("btnLoadMoreProjects");
    this.formFilterProjects = document.querySelector(".filtroProjetos");
    this.filterOptions = document.querySelectorAll(".label");
    this.openFilter = document.querySelector(".open-filter");
    this.closeFilter = document.querySelector(".close-filter");

    this.loading = document.querySelector('.loading');

    this.pagina = document.getElementById("pagina");

    this.listProjects = document.getElementById("listProjetos");

    this.ready();
  }

  ready() {

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) return;
      if(+this.pagina.value) {
        this.loaderProjects()
      } else {
        intersectionObserver.disconnect()
      }
    });
    intersectionObserver.observe(document.querySelector("#endListProjects"));

    this.openFilter.addEventListener("click", () => {
      this.formFilterProjects.classList.add("active");
      document.querySelector("body").classList.toggle("blockScroll");
      document.querySelector("html").classList.toggle("blockScroll");
    });

    this.closeFilter.addEventListener("click", () => {
      this.formFilterProjects.classList.remove("active");
      document.querySelector("body").classList.toggle("blockScroll");
      document.querySelector("html").classList.toggle("blockScroll");
    });

    this.formFilterProjects.addEventListener("submit", (event) => {
      event.preventDefault();

      this.filterProjects();
      this.formFilterProjects.classList.remove("active");
    });

    window.addEventListener("click", function (e) {
      if (e.target.closest(".filter-options") === null) {
        document.querySelectorAll(".filter-options.active").forEach((item) => {
          item.classList.remove("active");
        });
      }
    });

    this.filterOptions.forEach((item) => {
      item.addEventListener("click", (item) => {
        if (!item.target.parentNode.classList.contains("active")) {
          document
            .querySelectorAll(".filter-options.active")
            .forEach((item) => {
              item.classList.remove("active");
            });
        }

        item.target.parentNode.classList.toggle("active");
      });
    });
  }

  filterProjects() {
    let fieldCategoriaArray = [];
    let fieldStatusArray = [];
    let fieldAnoArray = [];

    this.listProjects.innerHTML = "";

    document
      .querySelectorAll(".fieldsCategory input:checked")
      .forEach((input) => {
        fieldCategoriaArray.push(input.value);
      });

    document
      .querySelectorAll(".fieldsStatus input:checked")
      .forEach((input) => {
        fieldStatusArray.push(input.value);
      });

    document.querySelectorAll(".fieldsYear input:checked").forEach((input) => {
      fieldAnoArray.push(input.value);
    });

    this.fieldCategoria = fieldCategoriaArray.join(",");
    this.fieldStatus = fieldStatusArray.join(",");
    this.fieldAno = fieldAnoArray.join(",");
    axios
      .get(url + "/ajax/get_projetos.php", {
        params: {
          categoria: this.fieldCategoria,
          status: this.fieldStatus,
          ano: this.fieldAno,
          pagina: pagina,
        },
      })
      .then((response) => {
        const total = parseInt(response.data.total);

        if (total > 0) {
          const projetos = response.data.projetos;
          projetos.forEach((projeto, index) => {
            this.listProjects.innerHTML += this.templateProjeto(
              projeto.link,
              projeto.imagem[0],
              projeto.titulo,
              projeto.chamada
            );
          });

          const totalProjectsScreen =
            document.querySelectorAll(".blockProjeto").length;

          if (totalProjectsScreen < total) {
            pagina++;
            this.pagina.value = pagina;
            document.getElementById("paginacao").style.display = "block";
          } else {
            this.pagina.value = 2;
            document.getElementById("paginacao").style.display = "none";
          }

          if (totalProjectsScreen == total) {
            document.getElementById("paginacao").style.display = "none";
          }
        } else {
          this.listProjects.innerHTML =
            "<span></span><h2 class='no-project'>Nenum projeto encontrado</h2>";
        }

        this.btnLoadMore.disabled = false;
      })
      .catch((error) => {
        console.log(error);
        this.btnLoadMore.disabled = false;
      });
  }

  loaderProjects() {
    let fieldCategoriaArray = [];
    let fieldStatusArray = [];
    let fieldAnoArray = [];

    document
      .querySelectorAll(".fieldsCategory input:checked")
      .forEach((input) => {
        fieldCategoriaArray.push(input.value);
      });

    document
      .querySelectorAll(".fieldsStatus input:checked")
      .forEach((input) => {
        fieldStatusArray.push(input.value);
      });

    document.querySelectorAll(".fieldsYear input:checked").forEach((input) => {
      fieldAnoArray.push(input.value);
    });

    this.fieldCategoria = fieldCategoriaArray.join(",");
    this.fieldStatus = fieldStatusArray.join(",");
    this.fieldAno = fieldAnoArray.join(",");

    // this.btnLoadMore.disabled = true;

    axios
      .get(url + "/ajax/get_projetos.php", {
        params: {
          categoria: this.fieldCategoria,
          status: this.fieldStatus,
          ano: this.fieldAno,
          pagina: pagina.value,
        },
      })
      .then((response) => {
        const total = parseInt(response.data.total);
        
        this.loading.classList.add('active')

        if (total > 0) {
          const projetos = response.data.projetos;
          projetos.forEach((projeto, index) => {
            this.listProjects.innerHTML += this.templateProjeto(
              projeto.link,
              projeto.imagem[0],
              projeto.titulo,
              projeto.chamada
            );
          });

          const totalProjectsScreen =
            document.querySelectorAll(".blockProjeto").length;

          if (totalProjectsScreen < total) {
            this.pagina.value++;
          } else {
            this.pagina.value = 0;
          }

          setTimeout(() => {
            document.querySelectorAll(".blockProjeto").forEach((item) => {
              item.classList.add('visible')
            })
          }, 500);
          
          this.loading.classList.remove('active')

        }


      })
      .catch((error) => {
        console.log(error);
      });
  }

  templateProjeto(link, imagem, titulo, texto) {
    const template = `
            <div class="blockProjeto">
                <a  class="clickable" href="${link}">
                    <picture>
                        <img src="${imagem}" alt="${titulo}">
                    </picture>
                    <div class="conteudo">
                        <h3>${titulo}</h3>
                        <div class="text">${texto == null ? "" : texto}</div>
                    </div>
                </a>
            </div>
        `;
    return template;
  }
}
