export default class Footer {
  constructor() {
      this.btnGoToTop = document.querySelector('.btn-top')
      this.ready();
  }

  ready() {

    if(this.btnGoToTop) {
        this.btnGoToTop.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        })
    }
      
  }
}