import axios from 'axios'
import Forms from '../general/forms'
import Bouncer from 'formbouncerjs/src/js/bouncer/bouncer'

export default class Contato {
  constructor() {
    this.btnOpenModalWork = document.querySelector('#btnOpenModalWorkForm')
    this.modalWork = document.querySelector('.modalWorkForm')
    this.closeModal = document.querySelector('.closeModal')
    this.btnSendWorkForm = document.querySelector('#btnSendWorkForm')

    this.ready()
    this.dropfiles()
  }

  ready() {
    this.btnOpenModalWork.addEventListener('click', () => {
      this.modalWork.classList.add('active')
    })

    this.closeModal.addEventListener('click', () => {
      this.modalWork.classList.remove('active')
      this.modalWork.querySelector('.containerModal').classList.remove('sucesso')
    })

    new Bouncer(`#formWork`, {
      fieldClass: 'error-field',
      disableSubmit: true,
      ...Forms.wrongMessages(),
    })

    document.addEventListener(
      'bouncerFormValid',
      (event) => {
        this.sendFormAjax(event.target)
      },
      false
    )

    let uploadField = document.getElementById('anexo')

    uploadField.addEventListener('change', (file) => {
      if (uploadField.files[0].size > 2500000) {
        document.getElementById('dropzone').parentNode.classList.add('error-size')
        document.getElementById('dropzone').classList.remove('hasFile')
        uploadField.value = ''
      }
    })
  }

  dropfiles() {
    let dropzone = document.getElementById('dropzone')
    let dropzone_input = dropzone.querySelector('.dropzone-input')
    let multiple = dropzone_input.getAttribute('multiple') ? true : false
    let uploadField = document.getElementById('anexo')

    ;['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
      function (event) {
        dropzone.addEventListener(event, function (e) {
          e.preventDefault()
          e.stopPropagation()
        })
      }
    )

    dropzone.addEventListener(
      'dragover',
      function (e) {
        this.classList.add('dropzone-dragging')
      },
      false
    )

    dropzone.addEventListener(
      'dragleave',
      function (e) {
        this.classList.remove('dropzone-dragging')
      },
      false
    )

    dropzone.addEventListener(
      'drop',
      function (e) {
        this.classList.remove('dropzone-dragging')
        let files = e.dataTransfer.files
        let dataTransfer = new DataTransfer()

        let for_alert = ''
        Array.prototype.forEach.call(files, (file) => {
          for_alert += '# ' + file.name + ' (' + file.type + ' | ' + file.size + ' bytes)\r\n'
          dataTransfer.items.add(file)
          if (!multiple) {
            return false
          }
        })

        let filesToBeAdded = dataTransfer.files
        dropzone_input.files = filesToBeAdded

        if (dropzone_input.files[0].size > 2500000) {
          document.getElementById('dropzone').parentNode.classList.add('error-size')
          document.getElementById('dropzone').classList.remove('hasFile')
          uploadField.value = ''
          return false
        }

        document.querySelector('.fileName').innerHTML = dropzone_input.files[0].name

        dropzone.classList.add('hasFile')
      },
      false
    )

    dropzone.addEventListener('click', function (e) {
      dropzone_input.click()
    })

    dropzone_input.addEventListener('change', () => {
      document.querySelector('.fileName').innerHTML = dropzone_input.files[0].name
      dropzone.classList.add('hasFile')
    })
  }

  static serializeJSON(form) {
    // Create a new FormData object
    const formData = new FormData(form)
    // const csrftoken = this.getCookie('csrftoken');

    // Create an object to hold the name/value pairs
    const pairs = {}

    // Add each name/value pair to the object
    for (const [name, value] of formData) {
      pairs[name] = value
    }

    // Return the JSON string
    return JSON.stringify(pairs, null, 2)
  }

  sendFormAjax(form) {
    this.btnSendWorkForm.disabled = true
    this.btnSendWorkForm.querySelector('#lblSend').classList.add('noShow')
    this.btnSendWorkForm.querySelector('#lblSending').classList.remove('noShow')

    const dataForm = new FormData(form)

    axios({
      method: 'POST',
      url: form.getAttribute('action'),
      data: dataForm,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        this.btnSendWorkForm.disabled = false
        this.btnSendWorkForm.querySelector('#lblSending').classList.add('noShow')
        this.btnSendWorkForm.querySelector('#lblSend').classList.remove('noShow')
        this.modalWork.querySelector('.containerModal').classList.add('sucesso')

        this.closeModal.addEventListener('click', () => {
          this.modalWork.classList.remove('active')
          this.modalWork.querySelector('.containerModal').classList.remove('sucesso')
          form.reset()
        })
      })
      .catch(() => {
        console.log('Erro')
        this.btnSendWorkForm.disabled = false
        this.btnSendWorkForm.querySelector('#lblSending').classList.add('noShow')
        this.btnSendWorkForm.querySelector('#lblSend').classList.remove('noShow')
      })
  }
}
