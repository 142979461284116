export default class Header {
    constructor() {

        this.header = document.querySelector('header')

        this.sideMenu = document.querySelector('.sideMenu')
        this.menuMobile = document.querySelector('#menuMobile')
        this.menu = document.querySelector('.fullMenu')
        this.closeMenu = document.querySelector('.closeMenu')

        this.ready();

    }

    ready() {

        document.addEventListener('scroll', (e) => {
            let scrollBarPosition = window.scrollY
            if(scrollBarPosition > 0) {
                this.header.classList.add('scroll')
                this.sideMenu.classList.add('hidden')
            } else {
                this.header.classList.remove('scroll')
                this.sideMenu.classList.remove('hidden')
            }

        })

		this.menuMobile.addEventListener("click", () => {
			this.menu.classList.add("active");
		}, false);
		
        this.closeMenu.addEventListener("click", () => {
			this.menu.classList.remove("active");
		}, false);
        
    }
}