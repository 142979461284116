export default class PhotoZoom {
    constructor() {

        this.btnOpenZoom = document.querySelectorAll('.openModalPhotoZoom')
        this.modal = document.getElementById('modalPhotoZoom')
        this.linkOpenImageFull = document.getElementById('linkOpenImage')
        this.closeModal = document.getElementById('closeModalZoom')

        this.ready();
    }

    ready() {

        this.btnOpenZoom.forEach(element => {
            element.addEventListener('click', () => {

                const urlImage = element.dataset.imagezoom
                const image = document.createElement('img');

                image.setAttribute('src',urlImage);

                this.linkOpenImageFull.setAttribute('href', urlImage)

                const box = document.getElementById('photoZoom');
                box.appendChild(image);

                this.modal.classList.add('active')
                this.closeModal.addEventListener('click', () => {
                    this.modal.classList.remove('active')
                    this.linkOpenImageFull.setAttribute('href', 'javascript:void(0);')
                    box.innerHTML = ''
                })
            })

        })

    }
}