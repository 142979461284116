import ScrollMagic from 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationTemplateProjetos {
    constructor() {
        this.ready();
    }
    
    ready() {
        
        const offsetAnimations = -300;
        const controller = new ScrollMagic.Controller();
        
        const templateProjetos = document.querySelector('.template-projetos');
        if(templateProjetos) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+templateProjetos.className
            })
            .setClassToggle("."+templateProjetos.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
    }
}