import IMask from "imask";

export default class Forms {
    constructor() {
        
        this.phoneField = document.querySelector(".telMask")
        this.dateField = document.querySelector(".dateMask")

        this.ready();
    }

    static serializeJSON(form) {
        const formData = new FormData(form);

        const pairs = {};

        for (const [name, value] of formData) {
            pairs[name] = value;
        }

        return JSON.stringify(pairs, null, 2);
    }

    static wrongMessages() {
        let mensagens;
        if(document.documentElement.lang === 'pt-br') {
            mensagens = {
                messages: {
                    missingValue: {
                        checkbox: "Este campo é obrigatório.",
                        radio: "Por favor selecione um valor.",
                        select: "Por favor selecione um valor.",
                        "select-multiple":
                            "Por favor selecione pelo menos um valor.",
                        default: "Por favor preencha este campo",
                    },
                    patternMismatch: {
                        email: "Insira um endereço de e-mail válido.",
                        url: "Informe uma url.",
                        number: "Informe o telefone",
                        date: "Por favor utilizar o formato DD/MM/YYYY",
                        default: "Please match the requested format.",
                    },
                    outOfRange: {
                        over: "Selecione um valor que não seja superior a {max}.",
                        under: "Selecione um valor que não seja inferior a {min}.",
                    },
                    wrongLength: {
                        over: "Reduza este texto para não mais que {maxLength} caracteres. Você está usando atualmente {length} caracteres.",
                        under: "Por favor, estenda este texto para {minLength} caracteres ou mais. Você está usando atualmente {length} caracteres.",
                    },
                },
            };
        } else {
            mensagens = {
                messages: {
                    missingValue: {
                        checkbox: "This field is required.",
                        radio: "Please select a value.",
                        select: "Please select a value.",
                        "select-multiple":
                            "Please select at least one value.",
                        default: "Please fill in this field",
                    },
                    patternMismatch: {
                        email: "Enter a valid email address.",
                        url: "Enter a url.",
                        number: "Inform the phone",
                        date: "Please use the format DD/MM/YYYY",
                        default: "Please match the requested format.",
                    },
                    outOfRange: {
                        over: "Please select a value that is no more than {max}.",
                        under: "Please select a value that is no less than {min}.",
                    },
                    wrongLength: {
                        over: "Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.",
                        under: "Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.",
                    },
                },
            };
        }
        return mensagens;
    }

    ready() {

        if(this.phoneField) {
            let phoneMask = IMask(this.phoneField, {
                mask: "(00) 00000-0000",
            });
        }

        if(this.dateField) {
            let dateMask = IMask(this.dateField, {
                mask: Date,
    
                pattern: "d/`m/`Y",
                blocks: {
                    d: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                    },
                    m: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                    },
                    Y: {
                        mask: IMask.MaskedRange,
                        from: 19000,
                        to: 20290,
                    },
                },
            });
        }
    }
}
