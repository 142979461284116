import { tns } from "tiny-slider";

export default class TemplateAreas {
  constructor() {
    this.content = document.querySelector('.template-areas').offsetTop
    this.hash = location.hash
    this.ready();

  }

  ready() {
    const tnsCarousel = document.querySelectorAll(".sliderTemplateAreas");
    if (tnsCarousel && !tnsCarousel.length) return false;

    tnsCarousel.forEach((slider) => {
      const sliderCurrent = tns({
        loop: false,
        container: slider,
        mouseDrag: true,
        controls: false,
        nav: false,
        autoHeight: false,
        onInit: (sliderInstance) => {
          sliderInstance.container.classList.remove("invisible");
        },
      });

      const rightSliderButton = document.querySelectorAll(".slideRight");
      rightSliderButton.forEach((button) => {
        button.addEventListener("click", () => {
          sliderCurrent.goTo("next");
          window.scrollTo({
            top: this.content - 100,
            behavior: "smooth"
          });
        });
      });

      const leftSliderButton = document.querySelectorAll(".slideLeft");
      leftSliderButton.forEach((button) => {
        button.addEventListener("click", () => {
          sliderCurrent.goTo("prev");
          window.scrollTo({
            top: this.content - 100,
            behavior: "smooth"
          });
        });
      });

      document.querySelectorAll(".area-atuacao").forEach((item) => {
        item.addEventListener("click", (item) => {
          window.scrollTo({
            top: this.content - 100,
            behavior: "smooth"
          });
          sliderCurrent.goTo(item.target.dataset.slide);
        });
      });
      
      if(this.hash) {
        window.scrollTo({
          top: this.content - 100,
          behavior: "smooth"
        });
        sliderCurrent.goTo(this.hash.replace('#',''));
      }
      
    });



  }
}
