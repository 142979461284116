import ScrollMagic from 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationArchiveProject {
    constructor() {
        this.ready();
    }
    
    ready() {
        
        const offsetAnimations = -300;
        const controller = new ScrollMagic.Controller();
        
        const archiveProjetos = document.querySelector('.archive-projetos');
        if(archiveProjetos) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+archiveProjetos.className
            })
            .setClassToggle("."+archiveProjetos.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
    }
}