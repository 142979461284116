import ScrollMagic from 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationHeader {
    constructor() {
        this.ready();
    }
    
    ready() {

        const offsetAnimations = -300;
        
        const moduloHeader = document.querySelector('header');
        setTimeout(() => {
            moduloHeader.classList.add('activeAnimations')
        }, 1000);
        
        const moduloSubMenu = document.querySelector('.sideMenu');
        setTimeout(() => {
            moduloSubMenu.classList.add('activeAnimations')
        }, 1300);

        const controller = new ScrollMagic.Controller();

        const templateContato = document.querySelector('.template-contato');
        if(templateContato) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+templateContato.className
            })
            // .setPin('activeAnimation')
            // .addIndicators({ name: 'contato' })
            .setClassToggle("."+templateContato.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
        const footer = document.querySelector('footer');
        if(footer) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: footer
            })
            // .setPin('activeAnimation')
            // .addIndicators({ name: 'footer' })
            .setClassToggle(footer, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
    }
}