import ScrollMagic from "scrollmagic";
import { CountUp } from "countup.js";
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationSingleSobre {
  constructor() {
    this.numbers = document.querySelectorAll(".numero");

    this.ready();
  }

  ready() {

    const offsetAnimations = -300;
    const controller = new ScrollMagic.Controller();

    const containerHero = document.querySelector(".container--hero");
    if (containerHero) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerHero.className
      })
        .setClassToggle("." + containerHero.className, "activeAnimations")
        .reverse(false)
        .addTo(controller);
    }

    const containerDados = document.querySelector(".container--dados");
    if (containerDados) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerDados.className
      })
        .setClassToggle("." + containerDados.className, "activeAnimations")
        .reverse(false)
        .on("start", () => {
          this.animateNumbers();
        })
        .addTo(controller);
    }

    const containerAreas = document.querySelector(".container--areas");
    if (containerAreas) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerAreas.className
      })
        .setClassToggle("." + containerAreas.className, "activeAnimations")
        .reverse(false)
        .addTo(controller);
    }

    const containerManifesto = document.querySelector(".container--manifesto");
    if (containerManifesto) {
      new ScrollMagic.Scene({
        triggerElement: "." + containerManifesto.className,
      })
        .setClassToggle("." + containerManifesto.className, "activeAnimations")
        .reverse(false)
        .addTo(controller);
    }

    const containerPeopleBlock = document.querySelector(
      ".container--people .bgBlock"
    );
    if (containerPeopleBlock) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerPeopleBlock.className,
      })
        .setClassToggle(
          "." + containerPeopleBlock.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerPeopleList = document.querySelector(
      ".container--people .listPeople"
    );
    if (containerPeopleList) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerPeopleList.className,
      })
        .setClassToggle("." + containerPeopleList.className, "activeAnimations")
        .reverse(false)
        .addTo(controller);
    }

    const containerEntrevistas = document.querySelector(
      ".container--entrevistas"
    );
    if (containerEntrevistas) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerEntrevistas.className,
      })
        .setClassToggle(
          "." + containerEntrevistas.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerDepoimentos = document.querySelector(
      ".container--depoimentos"
    );
    if (containerDepoimentos) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerDepoimentos.className,
      })
        .setClassToggle(
          "." + containerDepoimentos.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerPremiacoesImage = document.querySelector(
      ".container--premiacoes .imagemFull"
    );
    if (containerPremiacoesImage) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerPremiacoesImage.className,
      })
        .setClassToggle(
          "." + containerPremiacoesImage.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerPremiacoesHeaders = document.querySelector(
      ".container--premiacoes .headerSection"
    );
    if (containerPremiacoesHeaders) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerPremiacoesHeaders.className,
      })
        .setClassToggle(
          "." + containerPremiacoesHeaders.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerPremiacoesList = document.querySelector(
      ".container--premiacoes .listPremios"
    );
    if (containerPremiacoesList) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerPremiacoesList.className,
      })
        .setClassToggle(
          "." + containerPremiacoesList.className,
          "activeAnimations"
        )
        .reverse(false)
        .addTo(controller);
    }

    const containerClientes = document.querySelector(".container--clientes");
    if (containerClientes) {
      new ScrollMagic.Scene({
        offset: offsetAnimations,
        triggerElement: "." + containerClientes.className,
      })
        .setClassToggle("." + containerClientes.className, "activeAnimations")
        .reverse(false)
        .addTo(controller);
    }
  }

  animateNumbers() {
    const options = {
      separator: ".",
      prefix: "+",
    };

    this.numbers.forEach((number) => {
      let countUp = new CountUp(number, number.dataset.numero, options);
      countUp.start();
    });
  }
}
