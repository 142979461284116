import ScrollMagic from 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

export default class AnimationHome {
    constructor() {
        this.ready();

    }
    
    ready() {
        
        const offsetAnimations = -300;
        const controller = new ScrollMagic.Controller();
        
        const templateSobre = document.querySelector('.template-sobre');
        if(templateSobre) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+templateSobre.className
            })
            // .setPin('activeAnimation')
            // .addIndicators({ name: 'sobre' })
            .setClassToggle("."+templateSobre.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
        const templateAreas = document.querySelector('.template-areas');
        if(templateAreas) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+templateAreas.className
            })
            // .setPin('activeAnimation')
            // .addIndicators({ name: 'areas' })
            .setClassToggle("."+templateAreas.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
        const templateProjetos = document.querySelector('.template-projetos');
        if(templateProjetos) {
            new ScrollMagic.Scene({
                offset: offsetAnimations,
                triggerElement: "."+templateProjetos.className
            })
            // .setPin('activeAnimation')
            // .addIndicators({ name: 'projetos' })
            .setClassToggle("."+templateProjetos.className, "activeAnimations")
            .reverse(false)
            .addTo(controller);
        }
        
    }
}