import Swiper, { Navigation } from "swiper";

export default class SliderProjeto {
  constructor() {

    this.slider = document.querySelector(".swiper--projeto");
    this.sliderModal = document.querySelector(".swiper--projeto--modal");

    this.ready();
  }

  ready() {

    //Slider Projeto
    const fraction = this.slider.querySelector(
      ".slider-pagination--numbers"
    );
    const slides = this.slider.querySelectorAll(".swiper-slide");
    let slideCount = slides.length;

    fraction.innerHTML = `<span class="active">1</span> / <span>${slideCount}</span>`;

    const swiper = new Swiper(".swiper--projeto", {
      modules: [Navigation],
      grabCursor: true,
      slidesPerView: 1,
      allowTouchMove: true,
      navigation: {
          nextEl: ".slider-button-next--projeto",
          prevEl: ".slider-button-prev--projeto",
      },
      on: {
          init: (swiper) => {
              if (document.querySelectorAll(".swiper--projeto")) {
                  document
                      .querySelectorAll(".swiper--projeto")
                      .forEach((el) => {
                          let totalSlides = el.querySelectorAll(
                              ".swiper-wrapper .swiper-slide"
                          ).length;

                          if (totalSlides <= 1) {
                              el.querySelector(
                                  ".swiper-container--nav"
                              ).style.display = "none";
                          }
                      });
              }
          },
          slideChange: () => {
              let atualSlide = swiper.realIndex + 1;
              fraction.innerHTML = `<span class="active">${atualSlide}</span> / <span>${slideCount}</span>`;
          },
      },
    });

    //Abrir Modal
    slides.forEach((element, index) => {
      element.addEventListener('click', () => {
        document.querySelector('.modalSliderGaleria').classList.add('active')
        document.querySelector('html').classList.add('blockScroll')
      })
    })

    //Slider Modal
    const fractionModal = this.sliderModal.querySelector(
      ".slider-pagination--numbers"
    );
    const slidesModal = this.sliderModal.querySelectorAll(".swiper-slide");
    let slideCountModal = slidesModal.length;

    fractionModal.innerHTML = `<span class="active">1</span> / <span>${slideCountModal}</span>`;

    const swiperModal = new Swiper(".swiper--projeto--modal", {
      modules: [Navigation],
      grabCursor: true,
      slidesPerView: 1,
      allowTouchMove: true,
      navigation: {
          nextEl: ".slider-button-next--modal",
          prevEl: ".slider-button-prev--modal",
      },
      on: {
          init: (swiperModal) => {
              if (document.querySelectorAll(".swiper--projeto--modal")) {
                  document
                      .querySelectorAll(".swiper--projeto--modal")
                      .forEach((el) => {
                          let totalSlides = el.querySelectorAll(
                              ".swiper-wrapper .swiper-slide"
                          ).length;

                          if (totalSlides <= 1) {
                              el.querySelector(
                                  ".swiper-container--nav"
                              ).style.display = "none";
                          }
                      });
              }
          },
          slideChange: () => {
              let atualSlide = swiperModal.realIndex + 1;
              fractionModal.innerHTML = `<span class="active">${atualSlide}</span> / <span>${slideCount}</span>`;
          },
      },
    });

    //Fechar Modal Slider
    document.querySelector('.modalSliderGaleria #closeModalSliderZoom').addEventListener('click', () => {
      document.querySelector('.modalSliderGaleria').classList.remove('active')
      document.querySelector('html').classList.remove('blockScroll')
    })

  }
}
