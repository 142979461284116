import { tns } from "tiny-slider";

export default class Vitrine {
  constructor() {
    this.ready();
  }

  ready() {
    const tnsCarousel = document.querySelectorAll(".sliderVitrine");
    if (tnsCarousel && !tnsCarousel.length) return false;

    tnsCarousel.forEach((slider) => {
      const sliderCurrent = tns({
        loop: true,
        container: slider,
        mouseDrag: true,
        controls: false,
        nav: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoHeight: false,
        autoplay: {
          delay: 4000,
        },
        onInit: (sliderInstance) => {
          sliderInstance.container.classList.remove("invisible");
        },
      });
    });
  }
}
