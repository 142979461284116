import Swiper, { Navigation } from "swiper";
import { tns } from "tiny-slider";
import axios from "axios";

export default class Sobre {
    constructor() {
        this.entrevistas = document.querySelector(".swiper--entrevistas");
        this.depoimentos = document.querySelectorAll(".sliderDepoimentos");
        this.selectAreas = document.querySelectorAll("#selectState");
        this.listAreas = document.getElementById("listAreas");

        this.mapa = document.querySelectorAll(".mapa .active-zone path");


        this.ready();

        if (this.entrevistas) {
            this.sliderEntrevistas();
        }

        if (this.depoimentos.length > 0) {
            this.sliderDepoimentos();
        }

        if (this.selectAreas.length > 0) {
            this.areas();
        }
    }

    ready() {}

    map() {
        const dropdown = document.querySelector(".select-states-mobile");
        const dropdownContent = document.querySelector(".list-of-states");
        const dropdownContentLi = document.querySelectorAll(
            ".list-of-states .item"
        );
        const svg = document.querySelectorAll(".map-pos path");

        // console.log("plansMap Working");
        // console.log(svg);

        if (dropdown) {
            dropdown.addEventListener("click", function (event) {
                event.preventDefault();
                this.classList.toggle("active");
                dropdownContent.classList.toggle("active");
            });
        }

        if (dropdownContentLi) {
            dropdownContentLi.forEach(function (element) {
                element.addEventListener("click", function (event) {
                    event.preventDefault();

                    svg.forEach((element) => {
                        element.classList.remove("active");
                    });

                    document
                        .getElementById(this.dataset.estado)
                        .setAttribute("class", "active");
                });
            });
        }
    }

    sliderEntrevistas() {
        // const tnsCarousel = document.querySelectorAll(".sliderEntrevistas");
        // if (tnsCarousel && !tnsCarousel.length) return false;

        // tnsCarousel.forEach((slider) => {
        //     const sliderCurrent = tns({
        //         loop: false,
        //         container: slider,
        //         mouseDrag: true,
        //         controls: false,
        //         nav: true,
        //         autoHeight: false,
        //         onInit: (sliderInstance) => {
        //             sliderInstance.container.classList.remove("invisible");
        //         },
        //     });
        //     sliderCurrent.events.on('indexChanged', () => {
        //         const info = sliderCurrent.getInfo(),
        //         indexCurrent = info.displayIndex;
        //         let indexBefore = indexCurrent - 2
        //         if(indexCurrent === 1) {
        //             indexBefore = 0
        //         }

        //         const YouTubeContainers = document.querySelectorAll('.sliderEntrevistas .embed-youtube');
        //         if(YouTubeContainers) {
                    
        //             for (let i = 0; i < YouTubeContainers.length; i++) {
        //                 let container = YouTubeContainers[i];
        //                 if(container.querySelector('.embed-youtube iframe')) {
        //                     container.querySelector('.embed-youtube iframe').remove();
        //                     container.innerHTML = '<div class="embed-youtube-play"></div>'
        //                     let imageSource = container.dataset.videoImage;
        //                     let image = new Image();
        //                     image.src = imageSource;
        //                     image.alt = "Ronaldo Rezende - Assista ao vídeo";
        //                     image.addEventListener("load", function() {
        //                         container.appendChild(image);
        //                     });
        //                 }
        //             }
        //         }
        //     });
        // });

        //Slider Entrevistas
        const fraction = this.entrevistas.querySelector(".slider-pagination--numbers");
        const slides = this.entrevistas.querySelectorAll(".swiper-slide");
        let slideCount = slides.length;
    
        fraction.innerHTML = `<span class="active">1</span> / <span>${slideCount}</span>`;
    
        const swiper = new Swiper(".swiper--entrevistas", {
            modules: [Navigation],
            grabCursor: true,
            slidesPerView: 1,
            allowTouchMove: true,
            navigation: {
                nextEl: ".slider-button-next--entrevistas",
                prevEl: ".slider-button-prev--entrevistas",
            },
            on: {
                init: (swiper) => {
                    if (document.querySelectorAll(".swiper--entrevistas")) {
                        document
                            .querySelectorAll(".swiper--entrevistas")
                            .forEach((el) => {
                                let totalSlides = el.querySelectorAll(
                                    ".swiper-wrapper .swiper-slide"
                                ).length;
    
                                if (totalSlides <= 1) {
                                    el.querySelector(
                                        ".swiper-container--nav"
                                    ).style.display = "none";
                                }
                            });
                    }
                },
                slideChange: () => {
                    let atualSlide = swiper.realIndex + 1;
                    fraction.innerHTML = `<span class="active">${atualSlide}</span> / <span>${slideCount}</span>`;

                    const YouTubeContainers = document.querySelectorAll('.swiper--entrevistas .embed-youtube');
                    if(YouTubeContainers) {
                        
                        for (let i = 0; i < YouTubeContainers.length; i++) {
                            let container = YouTubeContainers[i];
                            if(container.querySelector('.embed-youtube iframe')) {
                                container.querySelector('.embed-youtube iframe').remove();
                                container.innerHTML = '<div class="embed-youtube-play"></div>'
                                let imageSource = container.dataset.videoImage;
                                let image = new Image();
                                image.src = imageSource;
                                image.alt = "Ronaldo Rezende - Assista ao vídeo";
                                image.addEventListener("load", function() {
                                    container.appendChild(image);
                                });
                            }
                        }
                    }

                },
            },
        });
    }

    sliderDepoimentos() {
        const tnsCarousel = document.querySelectorAll(".sliderDepoimentos");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach((slider) => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: false,
                autoHeight: false,
                controlsText: [
                    '<svg width="50" height="96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m48.5 1-47 47 47 47" stroke="#A2AEB3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    '<svg width="50" height="96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.5 1 47 47-47 47" stroke="#A2AEB3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                ],
                responsive: {
                    0: {
                        controlsText: [
                            '<svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1 1 7l6 6" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                            '<svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 1 6 6-6 6" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                        ],
                    },
                    900: {
                        controlsText: [
                            '<svg width="50" height="96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m48.5 1-47 47 47 47" stroke="#A2AEB3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                            '<svg width="50" height="96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.5 1 47 47-47 47" stroke="#A2AEB3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                        ],
                    },
                },
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                    const currentLog = document.querySelector(
                        ".totalItemsSliderDepoimentos #currentSlider"
                    );
                    const totalLog = document.querySelector(
                        ".totalItemsSliderDepoimentos #totalSlides"
                    );
                    currentLog.innerHTML = sliderInstance.slideBy;
                    totalLog.innerHTML = sliderInstance.slideCount;
                },
            });

            sliderCurrent.events.on("transitionStart", () => {
                var info = sliderCurrent.getInfo();
                const currentLog = document.querySelector(
                    ".totalItemsSliderDepoimentos #currentSlider"
                );
                currentLog.innerHTML = info.displayIndex;
            });
        });
    }

    areas() {
        this.mapa.forEach((el) => {
            el.addEventListener("click", () => {
                const selectedValue = el.id;

                const activeStates = document.querySelectorAll(".mapa a");
                activeStates.forEach((element) => {
                    element.classList.remove("active-state");
                });

                el.parentNode.classList.add("active-state");
                document.getElementById("selectState").value = selectedValue;

                const estado = selectedValue.toUpperCase();

                this.getAreas(estado);
            });
        });

        this.selectAreas.forEach((el) => {
            el.addEventListener("change", () => {
                const activeStates = document.querySelectorAll(".mapa a");
                activeStates.forEach((element) => {
                    element.classList.remove("active-state");
                });

                document
                    .getElementById(el.value)
                    .parentNode.classList.add("active-state");

                const estado = el.value.toUpperCase();

                this.getAreas(estado);
            });
        });
    }

    getAreas(estado) {
        axios
            .get(url + "/ajax/get_projetos.php", {
                params: {
                    estado: estado,
                },
            })
            .then((response) => {
                const total = parseInt(response.data.total);
                if (total > 0) {
                    this.listAreas.innerHTML = "";
                    const projetos = response.data.projetos;
                    projetos.forEach((projeto, index) => {
                        this.listAreas.innerHTML += `<li><a  class="clickable" href="${projeto.link}">${projeto.titulo}</a></li>`;
                    });
                } else {
                    this.listAreas.innerHTML = "<li>Projetos em andamento</li>";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
